<template>
  <div class="rankList" :style="`wdith:${listWidth}px;height:${listHeight}px`">
    <div class="rankTitle">
      <div
        class="titleItem"
        v-for="(item, index) in tableTitle"
        :key="index"
        :style="`width:${item.width}px`"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="rankContent">
      <template v-if="viewType == 'default'">
        <div
          class="contetnItem"
          :class="index < 3 ? 'topThree' : 'cusBorder'"
          v-for="(item, index) in list"
          :key="index"
        >
          <div
            class="titleItem"
            v-for="(i, j) in tableTitle"
            :key="j"
            :style="`width:${i.width}px`"
          >
            <template v-if="j != 0">
              <!-- 如果是图片 -->
              <template v-if="j == 1 && hasImg">
                <!-- <img :src="list[index][i.val]"> -->
                <img
                  src="https://img1.baidu.com/it/u=4048071112,566005454&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500"
                  alt=""
                />
              </template>
              <template v-else>
                <span class="ellipsis">{{ list[index][i.val] }}</span>
              </template>
            </template>
            <span v-else :class="index < 3 ? `top${index}` : 'topOther'">{{
              returnCount(index)
            }}</span>
          </div>
        </div>
      </template>
      <!-- 如果是普通排行榜 -->
      <template v-else>
        <div class="contetnItem" v-for="(item, index) in list" :key="index">
          <div
            class="titleItem"
            v-for="(i, j) in tableTitle"
            :key="j"
            :style="`width:${i.width}px`"
          >
            <!-- 需要排名显示 -->
            <template v-if="i.title == '排名'">
              <template v-if="j != 0">
                {{ list[index][i.val] }}
              </template>
              <span v-else>{{ returnCount(index) }}</span>
            </template>
            <template>
              {{ list[index][i.val] }}
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    tableTitle: {
      type: Array,
      default: () => [
        {
          title: '排名',
          width: '80',
        },
        {
          title: '姓名',
          val: 'first',
          width: '120',
        },
        {
          title: '课程次数',
          val: 'second',
          width: '100',
        },
        {
          title: '服务人次',
          val: 'three',
          width: '100',
        },
      ],
    },
    list: {
      type: Array,
      default: () => [
        {
          first: '袁鑫',
          second: '95',
          three: '89',
        },
        {
          first: '夏大如',
          second: '94',
          three: '98',
        },
        {
          first: '谢剑',
          second: '93',
          three: '88',
        },
        {
          first: '袁涛',
          second: '91',
          three: '60',
        },
        {
          first: '李航',
          second: '90',
          three: '71',
        },
        {
          first: '王铁山',
          second: '88',
          three: '68',
        },
        {
          first: '李伟剑',
          second: '85',
          three: '40',
        },
        {
          first: '张锡捷',
          second: '84',
          three: '31',
        },
        {
          first: '向军威',
          second: '81',
          three: '89',
        },
        {
          first: '胡丹',
          second: '80',
          three: '98',
        },
        {
          first: '梁巧',
          second: '79',
          three: '80',
        },
        {
          first: '李源',
          second: '78',
          three: '85',
        },
        {
          first: '娄宏博',
          second: '77',
          three: '60',
        },
        {
          first: '欧华星',
          second: '75',
          three: '97',
        },
        {
          first: '王卓',
          second: '74',
          three: '30',
        },
        {
          first: '文卫平',
          second: '72',
          three: '60',
        },
        {
          first: '肖理业',
          second: '68',
          three: '10',
        },
        {
          first: '周润东',
          second: '65',
          three: '50',
        },
      ],
    },
    listWidth: {
      type: Number,
      default: 400,
    },
    listHeight: {
      type: Number,
      default: 500,
    },
    viewType: {
      type: String,
      default: 'default',
    },
    hasImg: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    returnCount(count) {
      ++count
      return count < 10 ? `0${count}` : count
    },
  },
}
</script>

<style lang="scss" scoped>
.rankList {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 14px;
  .rankTitle {
    width: 100%;
    height: 50px;
    padding-right: 10px;

    display: flex;
    align-items: center;
    background: rgba(19, 35, 83, 0.87);
    border: 1px solid;
    border-image: linear-gradient(
        105deg,
        rgba(0, 99, 255, 0) 0%,
        #9ab6e3 100%,
        rgba(4, 75, 189, 0) 100%
      )
      1 1;
  }

  .rankContent {
    flex: 1;
    width: 100%;
    overflow-y: scroll;
    padding-right: 10px;
    margin-top: 10px;
    .contetnItem {
      display: flex;
      align-items: center;
      .titleItem {
        display: flex;
        align-items: center;
        justify-content: center;
        .ellipsis{
          width: 100%;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          // ↓↓↓  不要删除下面这行注释  ↓↓↓
          /* autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          -webkit-line-clamp:1;
        }
        span {
          width: 50px;
          line-height: 30px;
        }
      }
    }
    .contetnItem + .contetnItem {
      margin-top: 10px;
    }
  }

  .titleItem {
    text-align: center;
    line-height: 40px;
    img {
      width: 20px;
      height: 20px;
      line-height: 40px;
    }
  }

  .rankContent::-webkit-scrollbar {
    width: 3px;
  }

  .rankContent::-webkit-scrollbar-thumb {
    background-color: #85ace9;
  }

  .rankContent::-webkit-scrollbar-track {
    background-color: #020927;
  }

  .cusBorder {
    background: url('~@/assets/rankList/cusBorder.png');
    background-size: cover;
    border-radius: 4px;
  }
  .topThree {
    background: url('~@/assets/rankList/topThree.png');
    background-size: cover;
  }
  .top0 {
    background: url('~@/assets/rankList/top1.png');
    background-size: cover;
  }
  .top1 {
    background: url('~@/assets/rankList/top2.png');
    background-size: cover;
  }
  .top2 {
    background: url('~@/assets/rankList/top2.png');
    background-size: cover;
  }
  .topOther {
    background: url('~@/assets/rankList/top3.png');
    background-size: cover;
  }
}
</style>
